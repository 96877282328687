<template>
    <div class="flex flex-wrap xl:flex-nowrap gap-8 items-end">
        <div
            class="w-52 grow border border-gray-300 rounded-br-3xl bg-white p-4 relative flex flex-col justify-end lg:max-xl:w-80 duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg"
        >
            <svg
                class="fill-primary size-14 pr-2 pb-2 bg-white absolute -left-4 lg:-left-6 -top-6 aspect-square"
                viewBox="0 -960 960 960"
            >
                <path
                    d="m228-240 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T458-480L320-240h-92Zm360 0 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T818-480L680-240h-92Z"
                />
            </svg>

            <NuxtImg
                width="200"
                alt="Einkaufsleiterin, Augsburg"
                loading="lazy"
                class="w-1/2 ml-auto"
                src="/images/referenz-5.jpg"
            />

            <div class="relative uppercase text-sm -mt-4 -mr-2">
                <div class="bg-white inline-block px-1">Wir geben drei Daumen</div>
                <div class="bg-white inline-block px-1">für den <span class="font-black text-2xl">besten Preis,</span></div>
                <div class="bg-white inline-block px-1">Die tolle Abwicklung und den</div>
                <div class="bg-white inline-block px-1"><span class="font-black text-xl">Super Service.</span></div>
            </div>
            <div class="bg-white px-1 text-xs mt-4 text-right relative">Einkaufsleiterin, Augsburg</div>
        </div>

        <div
            class="w-72 grow border border-gray-300 rounded-br-3xl bg-white p-4 relative flex flex-col justify-end lg:max-xl:w-80 duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg"
        >
            <svg
                class="fill-primary size-14 pr-2 pb-2 bg-white absolute -left-4 lg:-left-6 -top-6 aspect-square"
                viewBox="0 -960 960 960"
            >
                <path
                    d="m228-240 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T458-480L320-240h-92Zm360 0 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T818-480L680-240h-92Z"
                />
            </svg>

            <NuxtImg
                width="200"
                alt="Hoteldirektorin, Offenbach"
                loading="lazy"
                class="w-1/2 ml-auto"
                src="/images/referenz-2.jpg"
            />

            <div class="relative uppercase text-sm -mt-8 -mr-2 ml-2">
                <div class="bg-white inline-block px-1 font-black text-2xl w-fit">Kostenlose</div>
                <div class="bg-white inline-block px-1 font-black text-2xl w-fit">Mitgliedschaft,</div>
                <div class="bg-white inline-block px-1">fantastischer Service und ein</div>
                <div class="bg-white inline-block px-1 font-black text-4xl">Autofilter,</div>
                <div class="bg-white inline-block px-1">
                    Der es mir leicht macht, den passenden Firmenwagen zu besten EInkaufskonditionen zu finden.
                </div>
                <div class="bg-white inline-block px-1"><span class="font-black text-xl">Fantastisch!</span></div>
            </div>
            <div class="bg-white px-1 text-xs mt-4 text-right relative">Hoteldirektorin, Offenbach</div>
        </div>

        <div
            class="w-52 grow border border-gray-300 rounded-br-3xl bg-white p-4 relative flex flex-col justify-end lg:max-xl:w-80 duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg"
        >
            <svg
                class="fill-primary size-14 pr-2 pb-2 bg-white absolute -left-4 lg:-left-6 -top-6 aspect-square"
                viewBox="0 -960 960 960"
            >
                <path
                    d="m228-240 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T458-480L320-240h-92Zm360 0 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T818-480L680-240h-92Z"
                />
            </svg>

            <NuxtImg width="200" alt="Gastronom, Kiel" loading="lazy" class="w-1/2 ml-auto" src="/images/referenz-3.jpg" />

            <div class="relative uppercase text-sm -mt-4 -mr-2">
                <div class="bg-white inline-block px-1">Unglaublich aber <span class="font-black text-xl">wahr.</span></div>
                <div class="bg-white inline-block px-1">Die Mitgliedschaft, die</div>
                <div class="bg-white inline-block px-1"><span class="font-black text-xl">nichts kostet</span> aber</div>
                <div class="bg-white inline-block px-1"><span class="font-black text-2xl">viel</span> gibt.</div>
            </div>
            <div class="bg-white px-1 text-xs mt-4 text-right relative">Gastronom, Kiel</div>
        </div>

        <div
            class="w-52 grow border border-gray-300 rounded-br-3xl bg-white p-4 relative flex flex-col justify-end lg:max-xl:w-80 duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg"
        >
            <svg
                class="fill-primary size-14 pr-2 pb-2 bg-white absolute -left-4 lg:-left-6 -top-6 aspect-square"
                viewBox="0 -960 960 960"
            >
                <path
                    d="m228-240 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T458-480L320-240h-92Zm360 0 92-160q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 23-5.5 42.5T818-480L680-240h-92Z"
                />
            </svg>

            <NuxtImg
                width="200"
                alt="Controllerin, Oberursel"
                loading="lazy"
                class="w-1/2 ml-auto"
                src="/images/referenz-4.jpg"
            />

            <div class="relative uppercase text-sm -mt-4 -mr-2">
                <div class="bg-white inline-block px-1">Für mich muss die</div>
                <div class="bg-white inline-block px-1">Preis-Leistungs-Relation</div>
                <div class="bg-white inline-block px-1">stimmen.</div>
                <div class="bg-white inline-block px-1"><span class="font-black text-xl">Hier stimmt sie.</span></div>
            </div>
            <div class="bg-white px-1 text-xs mt-4 text-right relative">Controllerin, Oberursel</div>
        </div>
    </div>
</template>

<script setup></script>
